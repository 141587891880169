<template>
  <section class="home d-flex align-items-center">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-6">
          <div class="home-text">
            <h1>HOMECARE PEDULI</h1>
            <p>
              Merupakan layanan unggulan yang dimilik oleh RSUD Gambiran Kota
              Kediri. Homecare Peduli merupakan inovasi layanan kesehatan yang
              menjangkau masyarakat ke rumah secara gratis dan responsif, dengan
              kelompok sasaran; masyarakat miskin, balita, lansia, dan difabel
              (kelompok rentan).
            </p>
          </div>
        </div>
        <div class="col-md-6 text-center">
          <video class="video-play" controls controlsList="nodownload">
            <source
              :src="require('../assets/videos/video.mp4')"
              data-wf-ignore="true"
            />
          </video>
        </div>
      </div>
    </div>
  </section>
  <section class="features section-padding">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="section-title">
            <h2>KONTRIBUSI TERHADAP CAPAIAN NASIONAL SDGS/TPB</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="feature-item">
            <div class="icon"><i class="fas fa-hospital"></i></div>
            <h3>Sasaran Pelayaanan</h3>
            <p>
              Sasaran pelayanan kesehatan kepada masyarakat bawah mampu
              menjangkau setiap warga miskin di Kota Kediri yang luput dari
              perhatian.
            </p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="feature-item">
            <div class="icon"><i class="fa fa-phone"></i></div>
            <h3>Akses yang Mudah</h3>
            <p>
              Menghubungi sambungan telepon/WA di nomor
              <strong>0812 1608 7000</strong>, siapapun bisa melaporkan
              orang-orang di sekitarnya yang mengalami sakit dan tak mampu
              menjangkau fasilitas kesehatan.
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="feature-item">
            <div class="icon"><i class="fa fa-hospital"></i></div>
            <h3>Kolaborasi Organ Pemerintah Lain</h3>
            <p>
              Terintegrasi dengan organ pemerintah lain (Dinas Sosial, Dinas
              Pendidikan, Dinas Pemberdayaan Perempuan, Perlindungan Anak,
              Pengendalian Penduduk dan Keluarga Berencana (DP3AP2KB),
              Dispendukcapil, Satpol PP, PKK) secara cepat membantu penyelesaian
              persoalan non kesehatan.
            </p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="feature-item">
            <div class="icon"><i class="fas fa-hospital"></i></div>
            <h3>Manfaat Homecare lainnya</h3>
            <p>
              Homecare PEDULI juga menyelesaikan kasus putus sekolah keluarga
              pasien, menghubungkan dengan Dinas Sosial untuk mendapat bantuan
              sosial, memberikan pelatihan kerja melalui Dinas Tenaga Kerja,
              serta kontribusi lain yang selaras dengan Pilar Pembangunan
              Ekonomi SDGs.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="how-it-works section-padding">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="section-title">
            <h2>Bagaimana <span>cara kerjanya</span></h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-6">
          <div class="how-it-works-item line-right">
            <div class="step">1</div>
            <h3>Hubungi</h3>
            <p>Menghubungi call centre di nomor telepon/WA 0812 1608 7000</p>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="how-it-works-item line-right">
            <div class="step">2</div>
            <h3>Penggalian Data</h3>
            <p>
              Tim Homecare PEDULI akan melakukan penggalian data melalui telepon
              tentang kondisi pasien.
            </p>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="how-it-works-item line-right">
            <div class="step">3</div>
            <h3>Bergerak ke lokasi.</h3>
            <p>
              Diagnosa awal kondisi pasien dilakukan untuk menentukan kondisi
              pasien perlu dibawa ke IGD atau cukup dilakukan perawatan di
              rumah.
            </p>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="how-it-works-item">
            <div class="step">4</div>
            <h3>Menggali lebih kondisi pasien.</h3>
            <p>
              Jika mengalami kesulitan ekonomi akan dihubungkan dengan Dinas
              Sosial untuk mendapatkan bansos. Atau ada jika ada anggota
              keluarga yang putus sekolah, akan dikoordinasikan dengan Dinas
              Pendidikan untuk mendapat beasiswa.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="how-it-works section-padding">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="section-title">
            <h2>Dokumentasi Kegiatan</h2>
          </div>

          <div
            id="carouselExampleControls"
            class="carousel slide"
            data-ride="carousel"
          >
            <div class="carousel-inner">
              <div
                v-for="item in images"
                :key="item.image"
                :class="
                  item.image == '20200305_104232.jpg'
                    ? 'carousel-item active'
                    : 'carousel-item'
                "
              >
                <img
                  :src="require('../assets/homecares/' + item.image)"
                  class="d-block w-100"
                  alt="..."
                />
              </div>
            </div>
            <button
              class="carousel-control-prev"
              type="button"
              data-target="#carouselExampleControls"
              data-slide="prev"
            >
              <span
                class="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span class="sr-only">Previous</span>
            </button>
            <button
              class="carousel-control-next"
              type="button"
              data-target="#carouselExampleControls"
              data-slide="next"
            >
              <span
                class="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span class="sr-only">Next</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="row justify-content-end fixed-bottom mr-5 mb-5">
    <a href="https://api.whatsapp.com/send?phone=6281216087000" target="blank"
      ><img :src="require('../assets/wa-button.png')" class="img-btn"
    /></a>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      images: [
        {
          image: "20200305_104232.jpg",
        },
        {
          image: "20200710_100908.jpg",
        },
        {
          image: "20200819_094301.jpg",
        },
        {
          image: "20201217_111840.jpg",
        },
        {
          image: "20210121_102457.jpg",
        },
        {
          image: "20210409_093235.jpg",
        },
        {
          image: "20210427_095709.jpg",
        },
        {
          image: "20210429_091705.jpg",
        },
        {
          image: "20210510_111056.jpg",
        },
        {
          image: "20210623_084437.jpg",
        },
        {
          image: "IMG-20211109-WA0002.jpg",
        },
        {
          image: "IMG-20211109-WA0003.jpg",
        },
        {
          image: "IMG-20211109-WA0005.jpg",
        },
        {
          image: "IMG-20211109-WA0006.jpg",
        },
        {
          image: "IMG-20211109-WA0008.jpg",
        },
        {
          image: "IMG-20211109-WA0009.jpg",
        },
        {
          image: "IMG-20211109-WA0011.jpg",
        },
        {
          image: "IMG-20211109-WA0012.jpg",
        },
        {
          image: "IMG-20211109-WA0015.jpg",
        },
        {
          image: "IMG-20211109-WA0016.jpg",
        },
        {
          image: "IMG-20211109-WA0017.jpg",
        },
        {
          image: "IMG-20211109-WA0019.jpg",
        },
        {
          image: "IMG-20211109-WA0022.jpg",
        },
        {
          image: "IMG-20211109-WA0023.jpg",
        },
        {
          image: "IMG-20211109-WA0025.jpg",
        },
        {
          image: "IMG-20211109-WA0026.jpg",
        },
        {
          image: "IMG-20211109-WA0027.jpg",
        },
        {
          image: "IMG-20211109-WA0030.jpg",
        },
        {
          image: "IMG-20211109-WA0031.jpg",
        },
        {
          image: "IMG-20211109-WA0032.jpg",
        },
        {
          image: "IMG-20211109-WA0033.jpg",
        },
        {
          image: "IMG-20211109-WA0037.jpg",
        },
        {
          image: "IMG-20211109-WA0040.jpg",
        },
        {
          image: "IMG-20211109-WA0044.jpg",
        },
        {
          image: "IMG-20211109-WA0048.jpg",
        },
        {
          image: "IMG-20211109-WA0051.jpg",
        },
        {
          image: "IMG-20211109-WA0052.jpg",
        },
        {
          image: "IMG-20211109-WA0054.jpg",
        },
        {
          image: "IMG-20211109-WA0058.jpg",
        },
        {
          image: "IMG-20211109-WA0061.jpg",
        },
        {
          image: "IMG-20211109-WA0070.jpg",
        },
      ],
    };
  },
  created() {
    document.title = "Homecare PEDULI RSUD GAMBIRAN KOTA KEDIRI";
  },
};
</script>

<style>
.img-btn {
  width: 70px;
  cursor: pointer;
}
</style>
