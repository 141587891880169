import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/pameran-online",
    name: "Pameran",
    component: () => import("../views/pameran/Pameran.vue"),
  },

  {
    path: "/idik",
    name: "Idik",
    component: () => import("../views/layanan/Idik.vue"),
  },
  {
    path: "/hemodialisa",
    name: "Hemodialisa",
    component: () => import("../views/layanan/Hemodisilia.vue"),
  },
  {
    path: "/mammography",
    name: "Mammography",
    component: () => import("../views/layanan/Mammography.vue"),
  },
  {
    path: "/audiometry",
    name: "Audiometry",
    component: () => import("../views/layanan/Audiometry.vue"),
  },
  {
    path: "/bronkoscopy",
    name: "Bronkoscopy",
    component: () => import("../views/layanan/Bronkoscopy.vue"),
  },
  {
    path: "/ct_scan_mri",
    name: "CTScanMri",
    component: () => import("../views/layanan/CTScanMri.vue"),
  },
  {
    path: "/endoscopy",
    name: "Endoscopy",
    component: () => import("../views/layanan/Endoscopy.vue"),
  },
  {
    path: "/eswl",
    name: "Eswl",
    component: () => import("../views/layanan/Eswl.vue"),
  },
  {
    path: "/fluoroscopy",
    name: "Fluoroscopy",
    component: () => import("../views/layanan/Fluoroscopy.vue"),
  },
  {
    path: "/usg",
    name: "USGFourDimensi",
    component: () => import("../views/layanan/USGFourDimensi.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
