<template>
  <div class="footer-copyright text-center py-3">
    <img
      :src="require('../assets/logo-pemkot.svg')"
      width="30"
      height="30"
      alt=""
    />
    Pemkot Kediri<br />Jalan Basuki Rahmad No.15, Kelurahan Pocanan, Kota
    Kediri, Jawa Timur 64146 (0354) 682955
  </div>
  <hr />
  <div class="footer-copyright text-center py-3">
    <img
      :src="require('../assets/logo-rsud.jpg')"
      width="30"
      height="30"
      alt=""
    />
    RSUD Gambiran<br />
    Jl. Kapten Piere Tendean No.16, Kota Kediri (0354) 2810000, 2810001, 2810008
  </div>
  <hr />
</template>
