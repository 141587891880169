<template>
  <div class="no-scroll-horizontal">
    <Navbar />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Navbar from "./components/Navbar.vue";
import Footer from "./components/Footer.vue";
export default {
  components: {
    Navbar,
    Footer,
  },
};
</script>

<style>
  .no-scroll-horizontal {
    overflow-x: hidden;
  }
</style>
