<template>
  <nav class="navbar navbar-expand-lg">
    <div class="container">
      <div class="row w-100 justify-content-between">
        <a class="navbar-brand ml-4" href="">
          <img src="../assets/logo-pemkot.svg" width="65" height="65" alt="" />
        </a>
        <div class="row justify-content-end">
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#collapsibleNavbar"
          >
            <i class="fas fa-bars" style="color: #3a3442"></i>
          </button>
        </div>
      </div>

      <!-- Navbar links -->
      <div class="collapse w-100 navbar-collapse" id="collapsibleNavbar">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <router-link class="nav-link" to="/">Beranda</router-link>
          </li>
           <li class="nav-item">
            <router-link class="nav-link" to="/pameran-online">Pameran Online</router-link>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              >Layanan Unggulan</a
            >
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <router-link to="/idik" class="dropdown-item">IDIK </router-link>
              <router-link to="/mammography" class="dropdown-item"
                >Mammography
              </router-link>
              <router-link to="/eswl" class="dropdown-item">ESWL </router-link>
              <router-link to="/endoscopy" class="dropdown-item"
                >Endoscopy Colonoscopy
              </router-link>
              <router-link to="/bronkoscopy" class="dropdown-item"
                >Bronkoscopy
              </router-link>
              <router-link to="/hemodialisa" class="dropdown-item"
                >Hemodialisa
              </router-link>
              <router-link to="/ct_scan_mri" class="dropdown-item"
                >CT SCAN dan MRI
              </router-link>
              <router-link to="/fluoroscopy" class="dropdown-item"
                >Pelayanan Fluoroscopy
              </router-link>
              <router-link to="/usg" class="dropdown-item"
                >USG 4 Dimensi</router-link
              >
              <router-link to="/audiometry" class="dropdown-item"
                >Audiometry
              </router-link>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
